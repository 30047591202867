[data-amplify-authenticator] {
  --amplify-components-authenticator-router-box-shadow: 0 0 16px var(--amplify-colors-overlay-10);
  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-authenticator-form-padding: var(--amplify-space-medium) var(--amplify-space-xl) var(--amplify-space-xl);
  --amplify-components-button-primary-background-color: var(--amplify-colors-neutral-100);
  --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px var(--amplify-colors-purple-60);
  --amplify-components-tabs-item-active-border-color: var(--amplify-colors-neutral-100);
  --amplify-components-tabs-item-color: var(--amplify-colors-neutral-80);
  --amplify-components-tabs-item-active-color: var(--amplify-colors-purple-100);
  --amplify-components-button-link-color: var(--amplify-colors-purple-80);
}

[data-amplify-authenticator][data-variation=modal] {
  position: relative !important;
  background-color: white !important;
}

[data-amplify-authenticator] [data-amplify-form]{
  padding-bottom: 15px !important;
}

.amplify-button--primary {
  background: #7F56D9 !important;
  border-radius: 8px !important;
  margin-top: 8px !important;
}

.amplify-input {
  border-color: lightgray !important;
}

.amplify-flex {
  gap: 6px !important;
}

.amplify-field-group {
  gap: var(--amplify-components-fieldgroup-gap) !important;
}

.amplify-button {
  border-color: lightgray !important;
}

.amplify-button--small {
  margin-top: 6px !important;
}

.amplify-label {
  /*margin-top: 6px !important;*/
  /*margin-bottom: 6px !important;*/
  color: #475467 !important;
}

.forgot-password {
  font-family: Inter, serif;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #6941C6;
}

.forgot-password:hover {
  text-decoration: underline;
}

.auth-left-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: radial-gradient(509.12% 102.14% at 41.88% 2.86%, #12073D 18.67%, #6941C6 87.67%, #7F56D9 100%);
}

.description-block {
  display: flex;
  flex-direction: column;
  justify-items: center;
  color: white;
  max-width: 608px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 56px;
}

.description-block-first-row {
  color: white;
  font-family: Inter, serif;
  font-size: 88px;
  font-weight: 450;
  line-height: 94px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 30px;
}

.block-body {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 187px;
  gap: 8px
}

.block-body-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.block-header-text {
  font-family: Inter, serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-left: 5px;
  color: white;
}

.block-body-text {
  font-family: Inter, serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: lightgray;
}

.ant-menu .ant-menu-item-selected {
  background-color: #f4f6f7 !important;
  color: #344054 !important;
}

.ant-layout .ant-layout-sider-children {
  padding: 32px 16px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.ant-menu .ant-menu-item {
  border-radius: 6px !important;
}

.ant-menu-item-icon {
  width: 24px !important;
  height: 24px !important;
  transition: 0.3s ease !important;
}

.ant-menu .ant-menu-item .ant-menu-item-icon span {
  margin-inline-start: 0 !important;
}

.ant-modal-footer {
  display: flex !important;
  flex-direction: row !important;
}

.add-product-modal .ant-modal-footer {
  margin-top: 20px !important;
  justify-content: flex-end !important;
}

.note-product-modal .ant-modal-footer {
  margin-top: 20px !important;
  justify-content: flex-end !important;
}

.ant-tabs {
  width: 100% !important;
}

/* .ant-tabs-tab-active {
  color: #6941c6 !important;
  background-color: transparent !important;
} */

.ant-tabs-nav .ant-tabs-tab {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.71 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #4b5563 !important;
  transition-duration: 0.5s !important;
}

.ant-tabs-nav:hover,
.ant-tabs-tab:hover {
  color: #6941c6 !important;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  /* background: #7f56d9 !important; */
}

.ant-switch.ant-switch-checked {
  background: #7f56d9 !important;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(248, 246, 246, 1.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

.outline-border {
  border: dotted 2px #6100ff !important;
}

.outline-border-selected {
  border: solid 2px #6100ff;
}

.ant-upload-wrapper .ant-upload-drag {
  border-radius: 8px !important;
  border: 1px solid #e4e7ec !important;
  min-height: 400px !important;
  background-color: #fff !important;
}

.ant-upload-wrapper .ant-upload-drag .ant-upload-drag-container {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 10px !important;
  background-color: #fff;
}

.ant-upload-wrapper .ant-upload-drag {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #fff;
}

.drap-and-drop-text-p {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  color: #373b42;
}

.drap-and-drop-text-p span {
  color: #7f56d9;
  font-weight: 600;
}

/* .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #6941c6;
} */
.ant-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #6941c6 !important;
}

.ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #6941c6 !important;
}

.ant-tabs .ant-tabs-tab-btn:active {
  color: #6941c6 !important;
}

.custom-modal-footer {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.custom-modal-footer-rigt-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.custom-modal-button-primary-purple {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 28px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #7f56d9;
  background-color: #7f56d9;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.custom-modal-button-cancel-white {
  display: flex;
  height: 40px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 18px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #d0d5dd;
  background-color: #fff;
  flex-grow: 0;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #344054;
}

.custom-modal-button-primary-purple:hover,
.custom-modal-button-cancel-white:hover {
  opacity: 0.8;
}

/* ImagesGalleryModal.css */
.shopify-images-container,
.aliexpress-images-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.gallery-image {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  object-fit: cover;
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.3s;
  background-color: #d0d5dd;
}

.gallery-image:hover {
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-modal-footer {
  display: flex !important;
  justify-content: flex-end !important;
  padding: 10px !important;
  border-top: none;
}

.custom-modal-button {
  margin-left: 10px;
}

.custom-modal-button-primary {
  background-color: #4caf50;
  border-color: #4caf50;
  color: #fff;
}

.custom-modal-button-cancel {
  background-color: #f44336;
  border-color: #f44336;
  color: #fff;
}

.shopify-images-container,
.aliexpress-images-container,
.preview-images-container {
  min-height: 400px;
  max-height: 500px;
  overflow-y: auto;
}

.gallery-edit-images-main-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 100%;
}
.gallery-edit-images-main-box .above-spinner .ant-spin-nested-loading {
  width: 100%;
  height: 100%;
}
.gallery-edit-images-main-box
  .above-spinner
  .ant-spin-nested-loading
  .ant-spin-container {
  width: 100%;
  height: 100%;
}
.gallery-edit-images-main-box .gallery-image-edit-container {
  /* /* width: 150px; */
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  border-radius: 8px;
  background-color: #d0d5dd;
  border: solid 1px #cad1d7;
}

.background-gallery-image-overlay-edit-image-section {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.edit-image-upper-portion {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}

.gallery-image-edit-button {
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: solid 1px #cad1d7;
  background-color: #fff;
}

.gallery-image-delete-button {
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: solid 1px #fee4e2;
  background-color: #fef3f2;
}

.edit-image-upper-portion .gallery-image-delete-button {
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: solid 1px #cad1d7;
  background-color: #fff;
}

.gallery-image-delete-button img,
.gallery-image-edit-button img {
  width: 20px;
  height: 20px;
  color: #b42318;
}

.edit-image-lower-portion {
  display: flex;
  justify-content: flex-start;
  padding: 5px;
}

/* .gallery-image-delete-button:hover,
.gallery-image-edit-button:hover {
  opacity: 0.8;
} */

.add-image-gallery-container {
  min-width: 160px;
  min-height: 160px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  background-position: center;
  justify-content: center;
  border-radius: 8px;
}

.add-image-gallery-container p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #6941c6;
}

.add-image-gallery-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.add-image-gallery-container:hover {
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
}

.ant-spin-nested-loading {
  width: 100% !important;
}

.ant-modal .ant-modal-footer {
  margin-top: 0px;
}

.next-prev-pagination-btn {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: left;
  color: #4b5563;
}

.ant-pagination .ant-pagination-item a {
  font-size: 14px;
  font-weight: 500;
  color: #3e444c;
  align-items: center;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ant-pagination-item .ant-pagination-item-1 .ant-pagination-item-active {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ant-pagination-item
  .ant-pagination-item-1
  .ant-pagination-item-active:hover
  a {
  color: #6100ff !important;
}

.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-selector.ant-select-selector-focused {
  box-shadow: 0 0 4px 2px rgba(175, 150, 229, 0.7) !important;
  border-color: rgba(127, 86, 217, 0.7) !important;
}

.ant-select-selector.ant-select-selector:hover {
  box-shadow: 0 0 4px 2px rgba(175, 150, 229, 0.7) !important;
  border-color: rgba(127, 86, 217, 0.7) !important;
}

.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-selector.ant-select-selector {
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 1.71 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #3e444c !important;
  width: 100px;
  height: 32px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #cad1d7;
  background-color: #fff;
}

.ant-select-item-option:hover {
  background-color: #f4f6f7 !important;
}

.ant-select-item-option-active {
  background-color: #f4f6f7 !important;
}

.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: #6941c6;
}

.input-height {
  height: 44px !important;
}

.text-editor-input {
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #cad1d7;
  background-color: #fff;
  width: 100%;
  resize: none;
  color: #3e444c;
  caret-color: #6941c6;
  height: auto !important;
  max-height: 500px !important;
}

.ql-toolbar.ql-snow {
  border: none !important;
  border-bottom: 1px solid #cad1d7 !important;
}

.ql-container.ql-snow {
  border: none !important;
}

.product-active-status:hover {
  color: #6941c6 !important;
}

/* .ant-pagination-disabled{
  color: ;
} */

.next-prev-pagination-btn:hover {
  color: #6941c6 !important;
}

.next-prev-pagination-btn[disabled] {
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  height: 51px !important;
}

.ql-editor {
  overflow-y: auto !important;
}

.ql-size-small {
  font-size: 12px !important;
}

.ql-size-large {
  font-size: 18px !important;
}

.ant-segmented .ant-segmented-item {
  width: 50% !important;
}

.ant-segmented .ant-segmented-item-label {
  display: flex !important;
  height: 44px !important;
  justify-content: center !important;
  align-items: center !important;
}

.ant-table-cell {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #3e444c;
  padding: 8px 0px 8px 16px !important;
}

.ant-input-outlined.ant-input-disabled,
.ant-input-outlined[disabled] {
  background-color: transparent !important;
}

:where(.css-kghr11).ant-input-outlined.ant-input-disabled:hover:not([disabled]),
:where(.css-kghr11).ant-input-outlined[disabled]:hover:not([disabled]) {
  background-color: transparent !important;
}

:where(.css-kghr11).ant-table-wrapper
  .ant-table-row-expand-icon-collapsed::before {
  display: none !important;
}

:where(.css-kghr11).ant-table-wrapper
  .ant-table-row-expand-icon-collapsed::after {
  display: none !important;
}

:where(.css-kghr11).ant-table-wrapper .ant-table-row-expand-icon::before {
  display: none !important;
}

:where(.css-kghr11).ant-table-wrapper .ant-table-row-expand-icon::after {
  display: none !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border: 1px solid var(--primary-color) !important;
  background-color: transparent !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
  background-color: var(--primary-color) !important;
}

.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  > .ant-collapse-header-text {
  /* display: block !important;
 */
  margin: 0 !important;
  flex: 0 !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center !important;
  justify-content: space-between !important;
  flex-direction: row-reverse !important;
}

.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  > .ant-collapse-header-text {
  flex: auto !important;
}

.ant-collapse {
  background-color: transparent !important;
}

.ant-table {
  border: 1px solid #e4e7ec;
  border-bottom: none !important;
  /* border-radius: 8px !important; */
}

.ant-table-wrapper .ant-table-tbody > tr > th,
.ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: 1px solid #e4e7ec !important;
}

.ant-divider-horizontal {
  display: none !important;
}

.ant-select-selection-item {
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
  color: #373b42 !important;
}

.ant-collapse
  > .ant-collapse-item-active
  > .ant-collapse-header
  .ant-collapse-expand-icon
  svg {
  transform: rotate(180deg) !important;
}

.ant-select-open > .ant-select-arrow svg {
  transform: rotate(180deg) !important;
}

.ant-table-row-expand-icon-expanded svg {
  transform: rotate(180deg) !important;
}
.ant-drawer-content-wrapper {
  width: 45% !important;
}
/* steps */
.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: transparent !important;
  border: 1px solid #5d6c7d !important;
  width: 16px !important;
  height: 16px !important;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: transparent !important;
  border: 1px solid var(--primary-color);
  width: 16px !important;
  height: 16px !important;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
  background-color: #5d6c7d !important;
  color: transparent !important;
}

.ant-steps.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  inset-inline-start: 8px !important;
}

.ant-steps-icon {
  content: "" !important;
  display: flex !important;
  width: 6px;
  height: 6px;
  background-color: var(--primary-color) !important;
  border-radius: 50%;
  margin: 0 !important;
  color: transparent !important;
}

.ant-steps-finish-icon {
  display: none !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  width: 16px !important;
  height: 16px !important;
  border: 1px solid var(--primary-color);
  background-color: transparent !important;
}

.ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}
.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: var(--primary-color) !important;
}
.ant-steps.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  padding: 15px 0 0px;
}
.ant-table-row {
  background-color: white !important;
}
.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
  outline: 5px solid var(--outline-color) !important;
  border: 1px solid var(--border-color) !important;
}
.anticon-search {
  transform: rotate(180deg) !important;
}
.table-row {
  cursor: pointer;
}
.ant-select-selection-placeholder {
  color: #3e444c !important;
}

:global {
  body.dragging,
  body.dragging * {
    cursor: grabbing !important;
  }
}

.price-question-mark {
  margin-top: -1px;
}
