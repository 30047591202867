.ant-tooltip-inner {
  background-color: white!important;
  border-color: mediumpurple !important;
  color: black!important;
}

.ant-tooltip .ant-tooltip-arrow:before {
  background: white;
  border-color: lightgray !important;
}

.ant-tooltip .ant-tooltip-arrow:after {
  background: mediumpurple!important;
}

.ant-slider .ant-slider-track {
  background-color: mediumpurple !important;
}

.ant-slider .ant-slider-handle:after {
  box-shadow: 0 0 0 2px mediumpurple!important;
}
