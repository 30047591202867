.right-section-main-container {
  right: 0;
  top: 64px;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 25%;
  background-color: #fff;
  border-left: solid 1px #e3e6ea;
  height: calc(100vh - 64px);
  overflow-y: auto;
  padding-bottom: 15%;
}
.title-container {
  height: 48px;
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 12px 16px;
  border-bottom: solid 1px #e3e6ea;
  background-color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: #101828;
  position: fixed;
  z-index: 1;
  width: 100%;
  transition-duration: 0.3;
}

.title-container h4 {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #101828;
}

.title-container .flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 18px;
}

.canva-button {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 2px 5px;
  border-radius: 8px;
  border: solid 1px #7f56d9;
  /* background-color: #7f56d9; */
  font-size: 15px;
  /*border: solid 1px #7f56d9;*/
  /*background-color: #8b3dff;*/
  color: #fff;
  font-weight: 400;
  /*font-family: Canva Sans, Noto Sans Variable, Noto Sans, -apple-system,
    BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  font-weight: 600;
  border-radius: 8px;*/
}
.canva-button:hover {
  background-color: #f4f6f7;

}
.canva-button.connected {
  /* background-color: #fff; */
  border: solid 1px #cad1d7;
  color: #373b42;
}
.canva-button.connected:hover {
  background-color: #f4f6f7;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  margin: 32px 0 0;
  padding: 0 16px;
}

.sale-price-input-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  margin: 12px 0 0;
  padding: 0 16px;
}

.input-label {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.43;
  text-align: left;
  color: #3e444c;
  transition-delay: 0.3s;
}

.form-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 8px;
  padding: 10px 14px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #cad1d7;
  background-color: #fff;
  width: 100%;
  resize: none;
  color: #3e444c;
  caret-color: #6941c6;
}

.text-editor-input {
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #cad1d7;
  background-color: #fff;
  width: 100%;
  resize: none;
  color: #3e444c;
  caret-color: #6941c6;
  height: auto !important;
  max-height: 500px !important;
}
.ql-toolbar.ql-snow {
  border-bottom: 1px solid #cad1d7 !important;
}

.form-input:focus {
  outline: none;
  border: solid 1px #cad1d7;
}

.delete-product-bullet-btn {
  width: 44px;
  height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  border: solid 1px #fee4e2;
  background-color: #fef3f2;
}
.delete-product-bullet-btn:hover {
  opacity: 0.9;
}
.add-another-bullet-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-right: 65px;
}

.add-another-bullet {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #cad1d7;
  background-color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #373b42;
  align-self: left;
  margin-top: 32px;
  margin-left: 16px;
}

/* .ql-container,
.ql-snow {
  max-height: 455px !important;
  overflow-y: auto !important;
} */

.input-main-container {
  margin-top: 48px;
}
