 

th {
    background-color: #f2f2f2;
}
 
  body {
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; */
    color: var(--primary-color)!important;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    font-size: var(--font-md);
    background-color: #f0f5f6!important;

    line-height: 1.4;
  }
  .date_1{
    font-size: 1rem;
    font-weight: bold;
  }
  p:not(.edit-formatted-line p) 

{   font-size: medium;
    color: black;
    line-height: 1.4rem;
}
 
/* .edit-formatted-line p{
  color: initial;
} */
.width_2{
    width: 60vw;
}

page {
    background: white;
padding: 50px;
    display: block;
    margin: 0 auto;
    margin-top: 0.5cm;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm var(--primary-color);
    /* box-shadow: 0 0 0.5cm rgba(0, 0, 0,0.5); */
  }
  page[size="A4"] {
    width: 60vw;
  }

  ul {
    list-style-type: disc; 
    padding-left: 20px;       /* Adds padding to make room for numbers */
  }
  
  li {
    color: black;
    margin-bottom: 10px;      /* Adds space between list items */
  }
  a{
    color: var(--primary-color);
  }