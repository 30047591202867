.variants-main-container {
    width: 100%;
    margin-bottom: 18px;
}

.color-size-text {


    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #344054;
    margin-bottom: 7px;
}

.option-text-styles {
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #373b42;
}

.right-variant-container {
    padding: 0 16px;
}

.variant-header-container {
    height: 48px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 12px 16px;
    border-bottom: solid 1px #e3e6ea;
    background-color: #fff;
    margin-bottom: 32px;

}

.variant-view-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #344054;
    margin-bottom: 6px;
}

.variant-header {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #101828;
}

.variant-view-styles {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 44px;
    /* flex-grow: 1; */
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #454d59;
    margin-bottom: 32px;
}

.variant-left-image-container {
    /* flex justtify-center items-center border rounded-[5px] w-[30px] h-[30px] */
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 0.9px #cad1d7;
    border-radius: 8px;
    width: 30px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;

    background-position: center;
    cursor: pointer;
    position: relative;
}

.variant-name-container {
    width: 50%;
    height: 44px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    gap: 8px;
    padding: 10px 14px;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    border: solid 1px #cad1d7;
    background-color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #373b42;
}

.variant-name-container:hover {
    outline: 5px solid var(--outline-color) !important;
    border: 1px solid var(--border-color) !important;
}

.variant-name-container:focus {
    outline: 5px solid var(--outline-color) !important;
    border: 1px solid var(--border-color) !important;
}

.left-variant-pills-content-container {
    min-width: 45px;
    max-width: auto;
    height: 28px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px 14px;
    border-radius: 21px;
    border: solid 0.9px #cad1d7;
    background-color: white;
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.left-variant-pills-content-container:hover {
    cursor: pointer;
    background-color: #f4f6f7;
}

.left-variant-pills-content-iamge-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    /* margin: 0 0 0 7px; */
    border-radius: 3.5px;
    border: solid 0.9px #e3e6ea;
}

.left-drop-down-image-styles {
    width: 100%;
}

.custom-disabled-input.ant-input[disabled] {
    background-color: transparent;
    /* Remove background */
    border-color: #d9d9d9;
    /* Set border color to default */
    color: rgba(0, 0, 0, 0.25);
    /* Set text color to default disabled color */
    cursor: not-allowed;
    /* Show not-allowed cursor */
    opacity: 1;
    /* Override opacity to keep text readable */
}
.custom-tooltip-variant-propertry .ant-tooltip-inner{
    color: #7f56d9 !important;
    border-color: #7f56d9 !important;
    background-color: white !important;
}