.outline-border1 {
  border: dotted 2px #6100ff;
}

.outline-border1.testimonial-pre-lander{
  border-right: dotted 2px #6100ff !important;
  border-top: dotted 2px #6100ff !important;
  border-bottom: dotted 2px #6100ff !important;
}
.outline-border-selected {
  border: solid 2px #6100ff !important;
}

.title-section {
  font-size: 26px;
  font-weight: bold;
  color: #000000;
}

.title-section:hover {
  cursor: pointer;
}

.edit-formatted-text-pre-lander {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #fff;
  white-space: pre-wrap;
}

.edit-formatted-text-pre-lander p {
  font-size: inherit !important;
  margin-bottom: 13px;
}

.edit-formatted-line-pre-lander {
  margin: 0;
  padding-bottom: 10px;
  font-size: 18px !important;
}

.text-editor-input-pre-lander {
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: solid 1px #cad1d7;
  background-color: #fff;
  width: 90%;
  resize: none;
  color: #3e444c;
  caret-color: #6941c6;
  height: auto !important;
  max-height: 500px !important;
  overflow-y: auto;
}

.button-pre-lander {
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
  background: #df7304;
  border-radius: 8px;
  padding: 5px;
  width: 100%;
}

.notification-block {
  font-size: 17px;
  font-weight: bold;
  color: #4d4646;
  background-color: #fefcdf;
  border: 2px solid #df7304;
  border-radius: 8px;
  margin: 10px 10px 10px 40px;
  padding: 15px;
  align-items: center;
}

.notification-block:hover {
  cursor: pointer;
}

.notify-pre-lander {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  background-color: #ffffff;
  border: 2px solid #195847;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
}

.testimonial-pre-lander {
  font-size: 17px;
  font-weight: bold;
  color: #9c9696;
  background-color: #eeeeee;
  border-left: 5px solid #1e9941;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 30px;
  font-style: italic;
}

/* .testimonial-pre-lander p {
  color: #9c9696;
} */

.testimonial-user-pre-lander {
  margin-top: 10px;
}

.right-bar-section-pre-lander {
  margin-left: 10px;
  position: sticky;
  height: 100vh;
  top: 65px;
  width: 60%;
  padding: 20px 10px;
}

.right-bar-section-header-pre-lander {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  color: #195847;
  line-height: 1.05;
}

.right-bar-section-reviews-pre-lander {
  font-size: 15px;
  margin-top: 10px;
  border-radius: 3px;
  background: white;
  padding: 10px 10px;
  color: black;
}
.right-bar-section-reviews-pre-lander .ant-progress-text {
  color: #009926;
}
.right-bar-section-reviews-pre-lander .ant-progress-inner,
.right-bar-section-reviews-pre-lander .ant-progress-bg {
  border-radius: 5px;
}

.right-bar-section-reviews-pre-lander .star-box {
  font-size: 13px;
  padding: 2px 3px;
  margin-right: 3px;
}

.right-bar-section-reviews-pre-lander .bg-success{
  background-color: #198754;
}

.right-bar-section-reviews-pre-lander .bg-gray{
  background-color: #6c757d;
}

.right-bar-section-cr-reviews-pre-lander {
  text-align: center;
  margin-top: 10px;
  border-radius: 3px;
  background: white;
  padding: 20px 10px;
}
.right-bar-section-cr-reviews-pre-lander h2 {
  font-weight: 700 !important;
  font-size: calc(0.5rem + 0.9vw);
  color: black;
}

.bottom-sticky-bar {
  background-color: rgba(207, 212, 233, 0.816);
  padding: 10px;
  position: fixed;
  bottom: 0;
  text-align: center;
  width: 70.2%;
  z-index: 10;
  display: block;
}

.prelander-view-page p {
  color: inherit !important;
  font-size: inherit !important;
}
