.pets-kids-temp-feature-div {
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 0.5rem 0;
}

.pets-kids-temp-feature-div h2 {
    display: inline-block;
    /* max-width: calc(100% - 6rem); */
    min-height: 1.6rem;
    margin: 0;
    word-break: break-word;
    font-size: 18px !important;

}

.icon-color-foreground-text path {
    fill: #7f56d9;
    /* Set the desired color */
}

.pets-kids-temp-shipping-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    list-style: none;
    padding: 5px 2px;
    gap: 1rem;
    font-size: 1.1rem;
    text-transform: uppercase;
    background-color: #f3edff;
    border: 2px dotted #7f56d9;
    border-radius: 8px;
    margin-top: 2rem;
    flex-wrap: wrap;
    padding: 2px 10px;
    width: 100%;
}

.pets-kids-temp-shipping-container span {
    display: flex;
    font-size: 12px;
    font-weight: 600;
    color: black;
    gap: 5px;
    align-items: center;
}

.pets-temp-heading-customer-rating-container {
    padding: 15px 12px 10px;
    border: 1px solid #7f56d9;
    border-radius: 10px;
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
}

.pets-temp-heading-customer-rating-container .img-rvw img {
    width: 120px !important;
    height: 120px !important;
    border-radius: 50%;
    margin-bottom: 7px;
}

.pets-temp-heading-customer-rating-container .img-rvw h4 {
    margin-top: 0;
    font-size: 14px;
    text-align: left;
    font-weight: 600;
    margin-bottom: 5px;
}

.pets-temp-heading-customer-rating-container .img-rvw .verify-cust {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #5bbf8a;
    font-size: 12px;
    font-weight: 500;
}

.pets-temp-heading-customer-rating-container .img-rvw .verify-cust img {
    width: 12px !important;
    height: 14px !important;
    object-fit: contain;
    margin: 0;
}

.cont-rvw {
    width: 60%;
}

.cont-rvw p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    font-style: italic;
    text-align: left;
}

.image-text-section-type-2 h2 {
    font-size: 34px;
    margin-bottom: 40px;
    margin-top: 20px;
    font-weight: 600;
}

.content-wrapper-type-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    row-gap: 24px;
    column-gap: 10px;
}

.image-left .image-container-type-2 {
    order: 1;
    box-shadow: 0 0 10px #0000001a;
    padding: 40px;
    border-radius: 13px;
    flex: 0 0 calc(50% - 5px);

}

.image-container-type-2 img {
    max-width: 100%;
    height: auto;
}

.text-container-type-2 {
    flex: 0 0 calc(50% - 5px);
    padding: 0 20px;
    text-align: center;
}

.button-container-type-2 {
    margin-top: 30px;
    text-align: center;
}

.cta-button-type-2,
.custom-button-type-2,
.custom-content-button {
    display: inline-block;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #7f56d9;
    font-size: 1rem;
    letter-spacing: .1rem;
    line-height: calc(1 + .2 / var(--font-body-scale));
    padding: 0.5rem 1.5rem 0.5rem;
    text-decoration: none;
    border: .1rem solid transparent;
}

.custom-features-section-type-2 {
    text-align: center;
    background-color: #7f56d9;
    padding: 30px 0;
}

.custom-features-section-heading-type-2 {
    font-size: 34px;
    margin-bottom: 20px;
    margin-top: 0;
    color: #fff;
    font-weight: 600;
}

.custom-features-section-subheading-type-2 p {
    font-size: 16px;
    font-weight: 400;
    color: #fff !important;
}

.custom-features-blocks-container-type-2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 15px 5rem 0;
    justify-items: center;
}

.custom-feature-block-item-type-2 {
    height: 100%;
    padding: 25px 25px 30px;
    background-color: #fff;
    border-radius: 10px;
    text-align: left;
    /* flex: 0 0 33.3%; */
}

.custom-feature-block-text-wrapper-type-2 {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.verified-text {
    color: #5bbf8a;
    font-size: 16px;
}

.custom-feature-block-right-text-type-2 {
    font-size: 16px;
    color: #121212;
}

.custom-feature-block-heading-type-2 {
    font-size: 22px;
    font-weight: 600;
    color: #7f56d9;
    margin-top: 15px;
    margin-bottom: 13px;
}

.custom-section-type-2 {
    padding: 30px 0;
    text-align: center;
}

.custom-section-heading-type-2,
.faq-accordion-type-2 h2 {
    font-size: 34px;
    margin-bottom: 30px;
    margin-top: 0;
    font-weight: 600;
}

.custom-section-content-type-2 {
    display: flex;
    justify-content: center;
}

.custom-blocks-wrapper-type-2 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    row-gap: 24px;
    column-gap: 8px;
    margin-bottom: 20px;
}

.custom-left-blocks-type-2,
.custom-right-blocks-type-2 {
    flex: 1;
    margin: 0 10px;
}

.custom-block-type-2 {
    text-align: left;
    margin-bottom: 26px;
}

.summary__title {
    display: flex;
    align-items: center;
    gap: 6px;
}

.summary__title h3 {
    display: inline-block;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: #7f56d9;
}

.custom-section-content-type-2 p {
    margin-top: 3px;
    color: #121212;
}

.custom-center-image-type-2 {
    flex: 0 0 auto;
    margin: 0 20px;
    border-radius: 50%;
    width: 330px;
    height: 330px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-center-image-type-2 img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.custom-slider-type-2-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #b18cd9;
    border-radius: 0;
    padding: 20px 20px 30px;
    /* position: relative; */
}

.custom-slider-type-2-inner img {
    width: 120px;
    height: 120px;
    margin: 0 auto;
}

.custom-slider-type-2-inner h3.name-title {
    font-weight: 700;
    font-size: 1.8rem;
    color: #fff;
    margin: 0;
}

.custom-slider-type-2-inner p {
    color: #fff;
}

.custom-content-section {
    padding: 30px 0;
    text-align: center;
    border-radius: 10px;
}

.custom-content-section-heading {
    font-size: 34px;
    border-bottom: 2px solid #7f56d9;
    font-weight: 600;
    padding: 20px;
    max-width: max-content;
    margin: 0 auto 30px;
}

.custom-content-section-heading span {
    background-color: #7f56d9;
    color: #fff;
}

.custom-content-body {
    margin-bottom: 30px;
    text-align: left;
}

.custom-content-block {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-content-icon {
    margin-right: 7px;
    line-height: 0;
}

.custom-content-icon img {
    max-width: 24px;
}

.custom-content-description {
    font-size: 18px;
    line-height: 1.5;
    color: #121212;
    margin: 13px 0;
    text-align: center;
}

.scribblesmart-section-template--24179347915097__image_with_content_type_6_apFHTr {
    background-color: #b18cd9;
    color: #ffffff;
    padding: 40px 50px;
    text-align: center;
}

.scribblesmart-section-template--24179347915097__image_with_content_type_6_apFHTr .section-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #ffffff;
    position: relative;
}

.scribblesmart-section-template--24179347915097__image_with_content_type_6_apFHTr .section-title:after {
    content: '';
    display: block;
    width: 50%;
    height: 2px;
    background-color: #ffde77;
    margin: 8px auto;
}

.scribblesmart-section-template--24179347915097__image_with_content_type_6_apFHTr .blocks-wrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
}

.scribblesmart-section-template--24179347915097__image_with_content_type_6_apFHTr .content-block {
    width: 23%;
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scribblesmart-section-template--24179347915097__image_with_content_type_6_apFHTr .block-heading {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #ffffff;
}

.scribblesmart-section-template--24179347915097__image_with_content_type_6_apFHTr .block-description {
    color: #ffffff;
    font-size: 16px;
    line-height: 1.5;

}

.custom-type-2-section {
    background-color: #7f56d9;
}

.custom-type-2-header .custom-type-2-heading {
    font-size: 34px;
    color: #fff;
    font-weight: 600;
    margin: 0 auto 34px;
}

.custom-type-2-image-wrapper img {
    max-width: 240px;
    height: 240px;
    object-fit: cover;
}

.custom-type-2-text-wrapper p {
    /* color: #fff; */
    font-size: 18px;
}


.custom-type-2-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
}

.custom-type-2-text-wrapper {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
}

.pets-temp-faq-opened-content-container {
    background-color: transparent;

    padding: "10px",
}
 

.pets-temp-faq-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    cursor: pointer;
    background-color: transparent;
}
.pets-collapse-outer-con{

    border-top: 1px solid black;
    border-bottom: 1px solid black;
}
.pets-collapse-outer-con:hover{
    background-color:#f5f5f5 !important;
}
/* custom section */
